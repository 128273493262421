/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'components/link';

export default function Logo(props) {
  const { darkMode, isWordMark, imgWidth } = props;

  if(darkMode){
    if(isWordMark) {
      return (
        <Link path="/" sx={styles.logo} {...props}>
          <img src={'/DarkWordmark.svg'} style={{width: imgWidth}} alt="The name Trelent spelt out in dark grey with two squares to the right of the capital T"/>
        </Link>
      );
    }
    else {
      return (
        <Link path="/" sx={styles.logo} {...props}>
          <img src={'/DarkMark.svg'} style={{width: imgWidth}} alt="A dark grey capital T with two blue squares to the right of top of the letter."/>
        </Link>
      );
    }
  }
  else {
    if(isWordMark) {
      return (
        <Link path="/" sx={styles.logo} {...props}>
          <img src={'/LightWordmark.svg'} style={{width: imgWidth}} alt="The name Trelent spelt out in white with two squares to the right of the capital T"/>
        </Link>
      );
    }
    else {
      return (
        <Link path="/" sx={styles.logo} {...props}>
          <img src={'/LightMark.svg'} style={{width: imgWidth}} alt="A white capital T with two blue squares to the right of top of the letter."/>
        </Link>
      );
    }
  }
}
const styles = {
  logo: {
    alignItems: 'center',
    cursor: 'pointer',
    display: 'inline-flex',
    svg: {
      height: 'auto',
      width: [128, null, '100%'],
    },
  },
};
