/** @jsx jsx */
import { jsx, Box, Heading, Text, Container } from 'theme-ui';
import { rgba } from 'polished';
import Logo from 'components/logo';
import { Link } from 'components/link';
import CallToAction from 'sections/call-to-action';
import { Fragment } from 'react';

export default function Footer() {
  return (
    <Fragment>
      <CallToAction />
      <Box as="footer" sx={styles.footer}>
        <Container>
          <Box sx={styles.footerTopInner}>
            <Box sx={styles.story}>
              <Heading sx={styles.heading} as="h1">
                Our Story
              </Heading>
              <Text as="p" sx={styles.copyright}>
                Trelent started out in 2018 by selling virtual desktops. Don't know what a virtual desktop is? Exactly.
                <br/>
                <br/>
                While we were scaling out our platform in 2021, we struggled with an ongoing argument internally: to document, or not to document?
                <br/>
                <br/>
                Then, the light-bulb moment: What if we didn't need to do it ourselves? Trelent as it is known today was born.
              </Text>
            </Box>
            <Box sx={styles.about}>
              <Box sx={styles.logo}>
                <Logo darkMode={true} isWordMark={true} imgWidth={"100%"}/>
              </Box>
              <Box sx={styles.terms}>
                <Link path="/legal/cookies">Cookie Policy</Link>
                <Text as="span">|</Text>
                <Link path="/legal/privacy">Privacy Policy</Link>
                <Text as="span">|</Text>
                <Link path="/legal/terms">Terms of Use</Link>
              </Box>
              <Text as="p" sx={styles.copyright}>
                &copy; {new Date().getFullYear()} Trelent Inc.
              </Text>
            </Box>
          </Box>
        </Container>
      </Box>
    </Fragment>
  );
}

const styles = {
  footer: {
    backgroundColor: '#F9FAFC',
    pt: [8, null, null, 10],
    pb: [8, null, null, 15],
  },
  footerTopInner: {
    gap: [30, null, 50, '20px 50px', 17, 50],
    display: ['block', null, null, null, null, 'grid'],
    gridTemplateColumns: [
      'repeat(2, 1fr)',
      null,
      null,
      'repeat(2, 1fr)',
      'repeat(2, 1fr)',
    ],
  },
  footerInner: {
    borderTop: `1px solid #D9E0E7`,
    display: ['block', null, 'flex'],
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '35px 0 40px',
  },
  about: {
    display: 'block',
    marginRight: [null, null, null, null, 'auto']
  },
  story: {
    display: 'block',
    marginLeft: [null, null, null, null, 'auto'],
    marginBottom: ['10', null, null, null, '0']
  },
  logo: {
    display: ['flex'],
    justifyContent: ['center', null, null, 'unset'],
    gridColumn: '1/2',
  },
  terms: {
    display: ['flex'],
    gridColumn: '3/4',
    alignItems: ['center', null, null, null, 'flex-start', 'center'],
    flexDirection: ['row', null, null, null, 'column', 'row'],
    justifyContent: [
      'center',
      null,
      'flex-start',
      'center',
      null,
      'flex-start',
    ],
    mt: [4, null, null, 0, 4],
    a: {
      color: 'heading',
      textDecoration: 'none',
    },
    span: {
      color: 'black',
      display: ['inline-flex', null, null, null, 'none', 'inline-flex'],
      m: ['0 10px'],
    },
  },
  copyright: {
    color: rgba('#0F2137', 0.6),
    fontSize: ['14px'],
    mt: [3, null, null, 3, 3],
    mr: [null, null, null, 'auto', 'unset'],
    gridColumn: '2/3',
    textAlign: 'left',
  },
  heading: {
    color: 'background',
    textAlign: ['center', null, null, 'left']
  }
};
