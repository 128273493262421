const menuItems = [
  {
    path: '/#home',
    label: 'Home',
  },
  {
    path: '/#services',
    label: 'Services',
  },
  {
    path: '/about',
    label: 'Our company',
  },
];

export default menuItems;
