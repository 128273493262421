/** @jsx jsx */
import { jsx, Container, Button, Image, Grid } from 'theme-ui';
import { useState } from 'react';
import { HiOutlineChevronDown } from 'react-icons/hi';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'contexts/drawer/drawer-provider';
import NavbarDrawer from './navbar-drawer';
import Logo from 'components/logo';
import { DropDownLink, NavLink } from 'components/link';

import menuItems from './header.data';

export default function Header() {
  const [state, setState] = useState({
    isMobileMenu: false,
    isSticky: false,
  });
  const handleStateChange = (status) => {
    status.status === Sticky.STATUS_FIXED
      ? setState({ ...state, isSticky: true })
      : setState({ ...state, isSticky: false });
  };

  return (
    <DrawerProvider>
      <Sticky
        enabled={true}
        top={0}
        activeClass="is-sticky"
        innerZ={100}
        onStateChange={handleStateChange}
      >
        <header
          sx={styles.header}
          className={state.isSticky ? 'is-sticky' : ''}
        >
          <Container sx={styles.container}>
            <Logo sx={styles.logo} isWordMark={true} darkMode={false} imgWidth={"50%"}/>
            <nav as="nav" sx={styles.navbar} className={'navbar'}>
              {menuItems.map(({ path, label }, i) => (
                <NavLink key={i} path={path} label={label} />
              ))}
              <div sx={styles.integrations}>
                <span>Integrations <HiOutlineChevronDown /></span>
                <div className='dropdownContent'>
                  <Grid gap={2} columns={3} rows={3}>
                    <DropDownLink integration={'VSC'} width={48}>
                      <Image src='/visualStudioCodeLogo.svg'/><h4>VS Code</h4>
                    </DropDownLink>
                    <DropDownLink integration={'IJ'}>
                      <Image src='/intellijLogo.svg' width={48}/><h4>IntelliJ</h4>
                    </DropDownLink>
                    <DropDownLink disabled={true} integration={'VS'}>
                      <Image src='/visualStudioLogo.svg' width={48}/><h4>Visual Studio</h4>
                    </DropDownLink>
                    <DropDownLink integration={'DC'}>
                      <Image src='/discordLogo.svg' width={48}/><h4>Discord</h4>
                    </DropDownLink>
                    <DropDownLink disabled={true} integration={'SK'}>
                      <Image src='/slackLogo.svg' width={48}/><h4>Slack</h4>
                    </DropDownLink>
                    <DropDownLink disabled={true} integration={'EC'}>
                      <Image src='/eclipseLogo.svg' width={48}/><h4>Eclipse</h4>
                    </DropDownLink>
                    <DropDownLink disabled={true} integration={'GH'}>
                      <Image src='/githubLogo.svg' width={48}/><h4>GitHub</h4>
                    </DropDownLink>
                    <DropDownLink disabled={true} integration={'GL'}>
                      <Image src='/gitlabLogo.svg' width={48}/><h4>GitLab</h4>
                    </DropDownLink>
                  </Grid>
                </div>
              </div>
            </nav>
            <Button
              sx={styles.buttonRight}
              variant="primary"
              onClick={() => window.location.href='https://discord.gg/3gWUdP8EeC'}
            >
              Join Our Community&nbsp;&nbsp;<Image src='/discordLogoWhite.svg' width={36}/>
            </Button>
            <NavbarDrawer />
          </Container>
        </header>
      </Sticky>
    </DrawerProvider>
  );
}

const styles = {
  header: {
    backgroundColor: 'transparent',
    position: 'fixed',
    left: 0,
    right: 0,
    py: [5],
    transition: 'background 0.3s ease-in-out 0s, padding 0.3s ease-in-out 0s',
    '&.is-sticky': {
      backgroundColor: 'muted',
      borderBottom: '1px solid #FFF2',
      py: [3],
    },
  },
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  navbar: {
    display: ['none', null, null, null, 'flex'],
    alignItems: 'center',
    a: {
      cursor: 'pointer',
      display: ['flex'],
      fontWeight: 400,
      padding: 0,
      transition: 'all 0.3s ease-in-out 0s',
      '+ a': {
        ml: [null, null, null, null, 4, 7],
      },
    },
    '.active': {
      color: 'primary',
    },
  },
  button: {
    display: ['none', null, null, null, 'inline-flex'],
    minHeight: 45,
    px: '18px',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
      pl: '3px',
    },
    ':hover': {
      svg: {
        pl: '5px',
      },
    },
  },
  buttonRight: {
    display: ['none', null, null, null, 'inline-flex'],
    marginLeft: "12px",
    minHeight: 45,
    px: '18px',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
      pl: '3px',
    },
    ':hover': {
      svg: {
        pl: '5px',
      },
    },
  },
  integrations: {
    marginLeft: '35px',
    position: 'relative',
    display: 'inline-block',
    '.dropdownContent': {
      borderRadius: '5px',
      display: 'none',
      position: 'absolute',
      right: '-10vw',
      minWidth: '0vw',
      backgroundColor: '#262626',
      color: 'foreground',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      padding: '12px 16px',
      transition: 'min-width 0.3s ease-in-out',
      zIndex: 1,
    },
    '&:hover': {
      '& .dropdownContent': {
        display: 'block',
        minWidth: '40vw',
      },
    }
  },
};
