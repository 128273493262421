/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import Header from './header/header';
import Footer from './footer/footer';
import { Auth0Provider } from '@auth0/auth0-react';

export default function Layout({ children }) {
  return (
    <Auth0Provider
      domain="auth.trelent.net"
      clientId="VRwMmoTKzbERO2rPlpx6XLLIOEwLgQVc"
      redirectUri="https://docgen.trelent.net"
      audience="https://trelent.us.auth0.com/api/v2/"
      scope="read:current_user update:current_user_metadata"
    >
        <React.Fragment>
          <Header />
          <main
            sx={{
              variant: 'layout.main',
            }}
          >
            {children}
          </main>
          <Footer />
        </React.Fragment>
    </Auth0Provider>
  );
}
