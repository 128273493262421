/** @jsx jsx */
import { jsx, Link as A } from 'theme-ui';
import { Link as GatsbyLink } from 'gatsby';
import { HiOutlineChevronRight } from 'react-icons/hi';

export function NavLink({ path, label, children, ...rest }) {
  return (
    <A href={path} className="nav-item" activeclass="active" spy={true} {...rest} sx={styles.learnMore}>
      {label}
    </A>
  );
}

export function DropDownLink({ path, label, children, disabled, integration, ...rest }) {
  if(disabled) {
    return (
      <div className="nav-item" activeclass="active" spy={true} {...rest} sx={styles.dropDownDisabled}>
        {children ? children : label}
      </div>
    );
  }

  return (
    <div role="button" tabIndex="-1" onClick={() => {openIntegration(integration)}} onKeyPress={(evt) => {if(evt.keyCode === 13){openIntegration(integration)}}} className="nav-item" spy={true} {...rest} sx={styles.dropDown}>
      {children ? children : label}
    </div>
  );
}

export function Link({ path, label, children, ...rest }) {
  return (
    <A as={GatsbyLink} to={path} {...rest}>
      {children ? children : label}
    </A>
  );
}

export function LearnMore({ path, label, children, ...rest }) {
  return (
    <A sx={styles.learnMore} href={path} {...rest}>
      {label ?? 'Learn More'} <HiOutlineChevronRight />
    </A>
  );
}

function openIntegration(integration) {
  switch(integration) {
    case 'VSC':
      if(window){
        window.open('vscode:extension/Trelent.trelent', '_blank').focus();
      }
      break;
    case 'VS':
      break;
    case 'EC':
      break;
    case 'IJ':
      if(window){
        window.open('https://plugins.jetbrains.com/plugin/18716-trelent--ai-docstrings-on-demand', '_blank').focus();
      }
      break;
    case 'GH':
      break;
    case 'GL':
      break;
    case 'OV':
      if(window){
        window.open('https://open-vsx.org/extension/Trelent/trelent', '_blank').focus();
      }
      break;
    case 'DC':
      if(window){
        window.open('https://discord.trelent.net/invite', '_blank').focus();
      }
      break;
    case 'SK':
      if(window){
        window.open('https://slack.trelent.net/slack/install', '_blank').focus();
      }
      break;
    default:
      break;
  }
}

const styles = {
  learnMore: {
    alignItems: 'center',
    color: 'white',
    cursor: 'pointer',
    display: 'inline-flex',
    fontSize: [1, null, null, 2],
    fontWeight: 500,
    textDecoration: 'none',
    svg: {
      transition: 'margin-left 0.3s ease-in-out 0s',
      ml: '3px',
    },
    ':hover': {
      svg: {
        ml: '5px',
      },
    },
  },
  dropDown: {
    cursor: 'pointer',
    borderRadius: '3px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    padding: '10px !important',
    textDecoration: 'none',
    ':hover': {
      background: '#3c3c3c',
    },
    h4: {
      padding: '10px',
      marginTop: 0,
      marginBottom: 0
    },
    p: {
      padding: '10px'
    },
    img: {
      height: '48px',
      marginRight: '20px'
    }
  },
  dropDownDisabled: {
    borderRadius: '3px',
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    marginLeft: '0px !important',
    padding: '10px !important',
    opacity: '40%',
    textDecoration: 'none',
    ':hover': {
      background: '#3c3c3c',
    },
    h4: {
      padding: '10px',
      marginTop: 0,
      marginBottom: 0
    },
    p: {
      padding: '10px'
    },
    img: {
      height: '48px',
      marginRight: '20px'
    }
  }
};
